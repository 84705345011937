






import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import PortalForm from '../components/PortalForm.vue';
import { PortalService } from '@/modules/portal/services/PortalService';
import { SavePortalEvent } from '@/modules/portal/views/PortalFormView.vue';
import { PortalTarget } from '@/modules/portal/domain/PortalTarget';

@Component({
    components: { Page, PortalForm },
})
export default class PortalCreative extends Vue {
  private isLoading: boolean = false;
  private portalId: string = '' ;
  private async createPortal(t: PortalTarget, n: string) {
    this.isLoading = true;
    try {
      const portalEvent: SavePortalEvent = {
        name: n,
        target: t,
        zones: [],
      };

      this.portalId = await PortalService.save(portalEvent, this.$store.getters['sessionStore/token']);
      this.$router.push('/view-portal/' + this.portalId);
    } catch {
      this.$notify.error({
        title: 'Erreur',
        message: 'Il y a eu une erreur de la sauvegarde ! Veuillez vérifier vos champs et réessayer.',
      });
    }
    this.isLoading = false;
  }
}
